@font-face {
  font-family: "anzhiyufont"; /* Project id 2508400 */
  src: url('./font_2508400_fpn9ui60u6q.eot?t=1690446183540'); /* IE9 */
  src: url('./font_2508400_fpn9ui60u6q.eot?t=1690446183540#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./font_2508400_fpn9ui60u6q.woff2?t=1690446183540') format('woff2'),
       url('./font_2508400_fpn9ui60u6q.woff?t=1690446183540') format('woff'),
       url('./font_2508400_fpn9ui60u6q.ttf?t=1690446183540') format('truetype'),
       url('./font_2508400_fpn9ui60u6q.svg?t=1690446183540#anzhiyufont') format('svg');
}
.anzhiyufont {
  font-family: "anzhiyufont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anzhiyu-icon-anzhiyu:before {
  content: "\e624";
}
.anzhiyu-icon-square-poll-vertical:before {
  content: "\e61e";
}
.anzhiyu-icon-universal-access:before {
  content: "\e61f";
}
.anzhiyu-icon-hourglass-start:before {
  content: "\e620";
}
.anzhiyu-icon-file-lines:before {
  content: "\e61b";
}
.anzhiyu-icon-stopwatch:before {
  content: "\e61c";
}
.anzhiyu-icon-font:before {
  content: "\e61d";
}
.anzhiyu-icon-creative-commons-nd-line:before {
  content: "\e617";
}
.anzhiyu-icon-creative-commons-by-line:before {
  content: "\e618";
}
.anzhiyu-icon-creative-commons-nc-line:before {
  content: "\e619";
}
.anzhiyu-icon-copyright-line:before {
  content: "\e61a";
}
.anzhiyu-icon-oranges:before {
  content: "\e614";
}
.anzhiyu-icon-facebook1:before {
  content: "\e63c";
}
.anzhiyu-icon-tiktok:before {
  content: "\e615";
}
.anzhiyu-icon-keyboard:before {
  content: "\e613";
}
.anzhiyu-icon-arrow-down:before {
  content: "\e611";
}
.anzhiyu-icon-scissors:before {
  content: "\f0c4";
}
.anzhiyu-icon-bolt:before {
  content: "\e610";
}
.anzhiyu-icon-circle-info:before {
  content: "\f05a";
}
.anzhiyu-icon-triangle-exclamation:before {
  content: "\e612";
}
.anzhiyu-icon-circle-check:before {
  content: "\f058";
}
.anzhiyu-icon-circle-minus:before {
  content: "\f056";
}
.anzhiyu-icon-circle-plus:before {
  content: "\f055";
}
.anzhiyu-icon-circle-dot:before {
  content: "\e616";
}
.anzhiyu-icon-copyright:before {
  content: "\e60e";
}
.anzhiyu-icon-qrcode:before {
  content: "\e60b";
}
.anzhiyu-icon-weibo:before {
  content: "\e60c";
}
.anzhiyu-icon-hand-heart-fill:before {
  content: "\e609";
}
.anzhiyu-icon-plant-fill:before {
  content: "\e60a";
}
.anzhiyu-icon-hashtag:before {
  content: "\e608";
}
.anzhiyu-icon-rss:before {
  content: "\e600";
}
.anzhiyu-icon-qq:before {
  content: "\e601";
}
.anzhiyu-icon-github:before {
  content: "\e602";
}
.anzhiyu-icon-bilibili:before {
  content: "\e603";
}
.anzhiyu-icon-paper-plane1:before {
  content: "\e607";
}
.anzhiyu-icon-heartbeat:before {
  content: "\e60d";
}
.anzhiyu-icon-jike:before {
  content: "\e649";
}
.anzhiyu-icon-repeat:before {
  content: "\e67a";
}
.anzhiyu-icon-shuffle1:before {
  content: "\e67b";
}
.anzhiyu-icon-arrows-rotate:before {
  content: "\e67c";
}
.anzhiyu-icon-pause:before {
  content: "\e67d";
}
.anzhiyu-icon-fw-eye:before {
  content: "\e679";
}
.anzhiyu-icon-bullhorn:before {
  content: "\e66f";
}
.anzhiyu-icon-stream:before {
  content: "\e670";
}
.anzhiyu-icon-caret-left:before {
  content: "\e671";
}
.anzhiyu-icon-folder-open:before {
  content: "\e672";
}
.anzhiyu-icon-thumbs-up:before {
  content: "\e673";
}
.anzhiyu-icon-angle-double-down:before {
  content: "\e674";
}
.anzhiyu-icon-sign-out-alt:before {
  content: "\e675";
}
.anzhiyu-icon-angle-double-left:before {
  content: "\e676";
}
.anzhiyu-icon-angle-double-right:before {
  content: "\e677";
}
.anzhiyu-icon-angle-left:before {
  content: "\e678";
}
.anzhiyu-icon-dice-d20:before {
  content: "\e66b";
}
.anzhiyu-icon-instagram:before {
  content: "\e66c";
}
.anzhiyu-icon-xmark:before {
  content: "\e66d";
}
.anzhiyu-icon-spinner:before {
  content: "\e66e";
}
.anzhiyu-icon-calendar-days:before {
  content: "\e60f";
}
.anzhiyu-icon-angle-right:before {
  content: "\e66a";
}
.anzhiyu-icon-pencil:before {
  content: "\e666";
}
.anzhiyu-icon-thumbtack:before {
  content: "\e667";
}
.anzhiyu-icon-location-dot:before {
  content: "\e668";
}
.anzhiyu-icon-fw-fire:before {
  content: "\e669";
}
.anzhiyu-icon-chart-line:before {
  content: "\e665";
}
.anzhiyu-icon-circle-xmark:before {
  content: "\e662";
}
.anzhiyu-icon-angle-down:before {
  content: "\e663";
}
.anzhiyu-icon-play:before {
  content: "\e661";
}
.anzhiyu-icon-paste:before {
  content: "\e65f";
}
.anzhiyu-icon-arrow-rotate-right:before {
  content: "\e660";
}
.anzhiyu-icon-radio:before {
  content: "\e659";
}
.anzhiyu-icon-forward:before {
  content: "\e65a";
}
.anzhiyu-icon-window-restore:before {
  content: "\e65b";
}
.anzhiyu-icon-backward:before {
  content: "\e65c";
}
.anzhiyu-icon-download:before {
  content: "\e65d";
}
.anzhiyu-icon-comment-medical:before {
  content: "\e65e";
}
.anzhiyu-icon-chevron-right:before {
  content: "\e655";
}
.anzhiyu-icon-arrows-left-right:before {
  content: "\e622";
}
.anzhiyu-icon-book-open:before {
  content: "\e653";
}
.anzhiyu-icon-list-ul:before {
  content: "\e654";
}
.anzhiyu-icon-comment-sms:before {
  content: "\e656";
}
.anzhiyu-icon-angles-right:before {
  content: "\e657";
}
.anzhiyu-icon-tag:before {
  content: "\e658";
}
.anzhiyu-icon-bars:before {
  content: "\e652";
}
.anzhiyu-icon-paper-plane:before {
  content: "\e605";
}
.anzhiyu-icon-house-chimney:before {
  content: "\e606";
}
.anzhiyu-icon-grip-vertical:before {
  content: "\e646";
}
.anzhiyu-icon-envelope:before {
  content: "\e604";
}
.anzhiyu-icon-music:before {
  content: "\e643";
}
.anzhiyu-icon-fan:before {
  content: "\e648";
}
.anzhiyu-icon-link:before {
  content: "\f0c1";
}
.anzhiyu-icon-chevron-left:before {
  content: "\e64b";
}
.anzhiyu-icon-rocket:before {
  content: "\e64c";
}
.anzhiyu-icon-lightbulb:before {
  content: "\e64d";
}
.anzhiyu-icon-shoe-prints1:before {
  content: "\e64e";
}
.anzhiyu-icon-images:before {
  content: "\e64f";
}
.anzhiyu-icon-box-archive:before {
  content: "\e650";
}
.anzhiyu-icon-artstation:before {
  content: "\e651";
}
.anzhiyu-icon-fish:before {
  content: "\e647";
}
.anzhiyu-icon-arrow-right:before {
  content: "\e627";
}
.anzhiyu-icon-arrow-left:before {
  content: "\e628";
}
.anzhiyu-icon-dice:before {
  content: "\e629";
}
.anzhiyu-icon-copy:before {
  content: "\e62a";
}
.anzhiyu-icon-cube:before {
  content: "\e62c";
}
.anzhiyu-icon-circle-arrow-right:before {
  content: "\f0a9";
}
.anzhiyu-icon-clock:before {
  content: "\e62e";
}
.anzhiyu-icon-book:before {
  content: "\e62f";
}
.anzhiyu-icon-calendar-alt:before {
  content: "\e630";
}
.anzhiyu-icon-eye-outline:before {
  content: "\e631";
}
.anzhiyu-icon-circle-half-stroke:before {
  content: "\e632";
}
.anzhiyu-icon-arrow-up:before {
  content: "\e633";
}
.anzhiyu-icon-file-word:before {
  content: "\e634";
}
.anzhiyu-icon-fire:before {
  content: "\e635";
}
.anzhiyu-icon-dove:before {
  content: "\e637";
}
.anzhiyu-icon-gear:before {
  content: "\e638";
}
.anzhiyu-icon-inbox:before {
  content: "\e639";
}
.anzhiyu-icon-history:before {
  content: "\e63a";
}
.anzhiyu-icon-magnifying-glass:before {
  content: "\e63b";
}
.anzhiyu-icon-message:before {
  content: "\e63d";
}
.anzhiyu-icon-moon:before {
  content: "\e63e";
}
.anzhiyu-icon-shapes:before {
  content: "\e63f";
}
.anzhiyu-icon-shuffle:before {
  content: "\e640";
}
.anzhiyu-icon-language:before {
  content: "\e641";
}
.anzhiyu-icon-tags:before {
  content: "\e642";
}
.anzhiyu-icon-train:before {
  content: "\e644";
}
.anzhiyu-icon-warning:before {
  content: "\f06a";
}
.anzhiyu-icon-ic_train:before {
  content: "\ea61";
}
.anzhiyu-icon-arrow-circle-right:before {
  content: "\e67f";
}
.anzhiyu-icon-yinle1:before {
  content: "\e888";
}
.anzhiyu-icon-filter-picture:before {
  content: "\e68f";
}
.anzhiyu-icon-comments:before {
  content: "\eb16";
}
.anzhiyu-icon-dengpao:before {
  content: "\e625";
}
.anzhiyu-icon-shoe-prints:before {
  content: "\f0b7";
}
.anzhiyu-icon-linggan:before {
  content: "\e6ca";
}
.anzhiyu-icon-sanmingzhi:before {
  content: "\fa92";
}
.anzhiyu-icon-zonglan:before {
  content: "\e623";
}
.anzhiyu-icon-danmu:before {
  content: "\e621";
}